<template>
 <footer>
   <div class="whatsapp"><a :href="'https://api.whatsapp.com/send?phone=' + info.whatsapp" target="_blank"><i class="fab fa-whatsapp"></i></a></div>
   
        <!-- Footer Start-->
        <div class="footer-main">
                <div class="footer-area footer-padding">
                    <div class="container">
                        <div class="row  justify-content-between">
                            <div class="col-lg-4 col-md-4 col-sm-8">
                                <div class="single-footer-caption mb-30">
                                    <!-- logo -->
                                    <div class="footer-logo">
                                        <a href="#"><img src="../assets/img/logo.png" alt=""></a>
                                    </div>
                                    <div class="footer-tittle">
                                        <div class="footer-pera">
                                            <p class="info1" v-html="info.footer_sobre"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-5">
                                <div class="single-footer-caption mb-50">
                                    <div class="footer-tittle">
                                        <h4><i class="fas fa-bars"></i> Menu</h4>
                                        <ul>
                                            <li><router-link class="list-inline-item" to="/">Home</router-link></li>
                                            <li><router-link class="list-inline-item" :to="{name:'Pagina', params:{pag:'sobre-a-empresa'}}">A Empresa</router-link></li>
                                            <li><router-link class="list-inline-item" :to="{name:'Pagina', params:{pag:'missao-visao-e-valores'}}">Missão, visão e valores</router-link></li>
                                            <li><router-link class="list-inline-item" :to="{name:'Pagina', params:{pag:'localizacao'}}">Localização</router-link></li>
                                            <li><router-link class="list-inline-item" :to="{name:'Pagina', params:{pag:'certificacao'}}">Certificação</router-link></li>
                                            <li><router-link class="list-inline-item" to="/contato">Contato</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-7">
                                <div class="single-footer-caption mb-50">
                                    <div class="footer-tittle">
                                        <h4><i class="fas fa-envelope"></i> Atendimento</h4>
                                        <div class="footer-pera">
                                            <p class="info1" v-html="info.endereco"></p>
                                        </div>
                                        <ul>
                                            <li><a href="#"><i class="fas fa-phone"></i> <span v-html="info.telefone"></span></a></li>
                                            <li><a :href="'https://api.whatsapp.com/send?phone=' + info.whatsapp" target="_blank"><i class="fab fa-whatsapp"></i> {{info.whatsapp}}</a></li>
                                            <li>{{info.email}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-8">
                                <div class="single-footer-caption mb-50">
                                    <!-- Map -->
                                    <div class="footer-tittle">
                                        <h4><i class="far fa-clock"></i> Funcionamento</h4>
                                    </div>
                                    <div class="map-footer">
                                       <p v-html="info.home_escolha"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- Footer End-->
        <div class="footer-fixed">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-8">
                <span>© Copyright Vix Inpeções  Todos os direitos reservados.</span>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 text-right">
                    Site desenvolvido por <a href="https://gouarts.com" target="_blank">GouArts Web </a>
                </div>
            </div>
        </div>
    </div>
    </footer>

</template>
<script>
import axios from 'axios';
export default {
    name: "Footer",
    created(){
        axios.get("https://sistema.gouarts.com/api/home?cliente=vitoriainspecoes").then(res => {
             this.info = res.data.configuracoes;
             
        }).catch(err => {
           console.log(err);
           
        })
    },
    data()
    {
        return {
            info: [],
        }
    }   
}
</script>
