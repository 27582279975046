<template>    
  <div>
    <header>
        <!-- Header Start -->
       <div class="header-area pt-4 pb-4">
            <div class="main-header">
                <div class="header-bottom header-sticky">
                    <div class="container">
                        <div class="row align-items-center">
                            <!-- Logo -->
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                <div class="logo">
                                    <!-- logo-1 -->
                                    <router-link class="big-logo" to="/"><img src="../assets/img/logo.png" alt=""></router-link>
                                    <!-- logo-2 -->
                                    <router-link class="small-logo" to="/"><img src="../assets/img/logo.png" alt=""></router-link>
                                </div>
                            </div>
                             <div class="col-xl-2 col-lg-2 col-md-2 topo-info-box d-sm-block d-none">
                                 <i class="fa fa-phone" aria-hidden="true"></i>
                                 <div class="topo-info rounded text-right">
                                    <ul>     
                                        <li v-html="infotopo.telefone"></li>
                                    </ul>
                                </div>
                             </div>
                              <div class="col-xl-3 col-lg-3 col-md-3 topo-info-box d-sm-block d-none">
                                  <i class="fa fa-envelope" aria-hidden="true"></i>
                                  <div class="header-info-left text-right topo-info rounded">
                                    <ul>     
                                        <li>{{infotopo.email}}</li>
                                       
                                    </ul>
                                </div>
                             </div>             
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 pt-2">                               
                                <div class="header-right-btn f-right topo-info rounded">
                                    <a class="topo-info-whatsapp" :href="'https://api.whatsapp.com/send?phone=' + infotopo.whatsapp" target="_blank">AGENDE SUA VISTORIA <img src="../assets/img/topowhatsp.png" alt=""></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
               </div>
               
            </div>
       </div>
        <!-- Header End -->
    </header>
    <div class="menutopo">
        <div class="col-xl-12 col-lg-12 col-md-12">
             <div class="center-content-center container">
                <nav class="navbar navbar-expand-lg" v-bind:class=" { 'navbarOpen': show }">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#menuprincipal" @click.stop="toggleNavbar()">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                </button>
                <div class="collapse navbar-collapse" id="menuprincipal" v-bind:class="{ 'show': show }">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item active"><router-link class="nav-link" to="/">HOME</router-link></li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="empresa" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            A EMPRESA
                            </a>
                            <div class="dropdown-menu" aria-labelledby="empresa">
                                <router-link class="dropdown-item" :to="{name:'Pagina', params:{pag:'sobre-a-empresa'}}">Sobre a Empresa</router-link>
                                <router-link class="dropdown-item" :to="{name:'Pagina', params:{pag:'missao-visao-e-valores'}}">Missão, visão e valores</router-link>
                                <router-link class="dropdown-item" :to="{name:'Pagina', params:{pag:'localizacao'}}">Localização</router-link>
                                <router-link class="dropdown-item" :to="{name:'Pagina', params:{pag:'certificacao'}}">Certificação</router-link>
                            </div>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="servicos" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            SERVIÇOS
                            </a>
                            <div class="dropdown-menu" aria-labelledby="servicos">
                                <router-link v-for="categoria in produtos_categorias" :key="categoria.id" class="dropdown-item" :to="`/produtos/${categoria.id}/${categoria.slug}`">{{categoria.titulo}}</router-link>
                            </div>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/noticias">NOTÍCIAS</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name:'Pagina', params:{pag:'precos'}}">PREÇOS</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name:'Pagina', params:{pag:'legislacao'}}">LEGISLAÇÃO</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/contato">FALE CONOSCO</router-link>
                        </li>
                        
                    </ul>
                    
                </div>
            </nav>
    </div>    
    </div>                       
   </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
    name: "Header",
    created(){
        axios.get("https://sistema.gouarts.com/api/home?cliente=vitoriainspecoes").then(res => {
             this.infotopo = res.data.configuracoes;
             
        }).catch(err => {
           console.log(err);
           
        })

        axios.get("https://sistema.gouarts.com/api/lista_categorias?cliente=vitoriainspecoes").then(res => {
             this.produtos_categorias = res.data.categorias;
             
        }).catch(err => {
           console.log(err);
           
        })
    },
     props: {
        msg: String
    },
    data()
    {
        return {
            infotopo: [],
            produtos_categorias: [],
            show: false
        }
    },
    methods: {
        toggleNavbar() {
            this.show = !this.show
         }
    }

}
</script>
<style scoped>
@media only screen and (min-width: 576px) and (max-width: 767px) {
   

}

@media (max-width: 576px)
{
   #menuprincipal {
    z-index:1000;
    
}
#menuprincipal ul {
    background: #ddd;
}
}

</style>